import env from './.env';
import { Environment } from '@app/model/environment.model';
export const environment = {
    production: false,
    version: env.npm_package_version + '-test',
    serverUrl: '/',
    apiUrl: 'https://licenseesales.restaurant.api-test.cfadevelop.com',
    proxyurl: 'https://cors-anywhere.herokuapp.com/',
    apiKey: '...',
    defaultLanguage: 'en-US',
    supportedLanguages: [
        'en-US',
        'fr-FR'
    ],
    s3Url: 'https://s3.us-east-1.amazonaws.com/test-cfabeyondtherestauranttest-us-east-1-licensee-sales-upload/',
    clientId: '0oa16li28bjXz5eoI0h8',
    issuer: 'https://cfahome.oktapreview.com/oauth2/auscjsakz3PaAP82K0h7',
    redirectUri: 'https://test.cfalicenseesales.com/implicit/callback',
    scope: 'licensee-sales-portal:read',
    gtmId: 'G-SSLG3HFFMC',
    lspReactAppUrl: "https://app.stsfcr-test.cfadevelop.com/lsp-react"
} as Environment;
